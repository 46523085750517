/**
 * Checks if user is verified and valid.
 * If user is not verified or valid, it will open the verification modal.
 * @param user - User object.
 * @param verificationModal - Bootstrap modal object.
 * @returns {boolean} - True if user is verified and valid, false otherwise.
 */
export function isUserVerifiedAndValid(user: any, verificationModal: any, regulated: boolean): boolean {
    if (regulated === false && (user?.email_verified === false || user?.is_valid === false)) {
        verificationModal?.show();
        return false;
    }
    return true;
}
