import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNoSpecialCharacters]'
})
export class NoSpecialCharactersDirective {
  private regex: RegExp = /^[a-zA-Z0-9 ]*$/; // Alphanumeric characters and spaces

  constructor(private el: ElementRef, private control: NgControl) { }

  // Keydown event to prevent special characters from being typed
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const key = event.key;

    // Allow control keys like backspace, tab, arrow keys, etc.
    if (key === 'Backspace' || key === 'Tab' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Enter') {
      return;
    }

    // Prevent special characters based on the regex
    if (!this.regex.test(key)) {
      event.preventDefault();
    }
  }

  // Paste event to remove special characters
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedText = clipboardData.getData('text');

    // Remove special characters from pasted text
    pastedText = pastedText.replace(/[^a-zA-Z0-9 ]/g, '');

    // Prevent default paste and insert sanitized text
    event.preventDefault();
    this.el.nativeElement.value = pastedText;

    // Update the form control or ngModel value
    if (this.control) {
      this.control?.control?.setValue(pastedText);
    }
  }

  // Input event to ensure the value doesn't contain special characters
  @HostListener('input', ['$event'])
  onInputChange(): void {
    let inputValue: string = this.el.nativeElement.value;

    // Remove special characters from the current value
    inputValue = inputValue.replace(/[^a-zA-Z0-9 ]/g, '');

    // Update the input value with the cleaned one
    this.el.nativeElement.value = inputValue;

    // Update the form control or ngModel value
    if (this.control) {
      this.control?.control?.setValue(inputValue);
    }
  }
}
