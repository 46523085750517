import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNoLeadingSpace]'
})
export class NoLeadingSpaceDirective {

  constructor(private el: ElementRef, @Optional() private ngControl: NgControl) { }

  // Handle keydown (to prevent leading and consecutive spaces during typing)
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const inputValue: string = this.el.nativeElement.value;
    const isSpaceKey = event.code === 'Space' || event.key === ' ';

    // Prevent leading space
    if (isSpaceKey && inputValue.length === 0) {
      event.preventDefault();
    }

    // Prevent consecutive spaces
    if (isSpaceKey && inputValue.endsWith(' ')) {
      event.preventDefault();
    }
  }

  // Handle input changes (to sanitize spaces after typing or pasting)
  @HostListener('input', ['$event'])
  onInputChange(): void {
    let inputValue: string = this.el.nativeElement.value;

    // Remove leading spaces
    inputValue = inputValue.replace(/^\s+/g, '');

    // Replace multiple spaces with a single space between words
    inputValue = inputValue.replace(/\s\s+/g, ' ');

    // Update the input element value
    this.el.nativeElement.value = inputValue;

    // If using ngControl, update the control value
    if (this.ngControl) {
      this.ngControl.control?.setValue(inputValue);
    }
  }

  // Handle paste events (to sanitize pasted content)
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    event.preventDefault(); // Prevent the default paste behavior

    // Get pasted text from clipboard
    const clipboardData = event.clipboardData;
    let pastedText = clipboardData?.getData('text') || '';

    // Sanitize the pasted text: remove leading spaces and multiple consecutive spaces
    pastedText = pastedText.replace(/^\s+/g, '').replace(/\s\s+/g, ' ');

    // Insert sanitized text into the input element
    document.execCommand('insertText', false, pastedText);

    // Update the value of the control if using ngControl
    if (this.ngControl) {
      this.ngControl.control?.setValue(this.el.nativeElement.value);
    }
  }

}
