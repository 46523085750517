import { Injectable } from '@angular/core';
import { NftService } from '../../services/nft.service';
@Injectable({
    providedIn: 'root'
})
export class FilterHelper {
    constructor(private nftService: NftService) { }
    /**
     * This function takes the current filters as an argument and returns an array of strings which represent
     * the types of sort options that should be shown. The logic is as follows:
     * - If for sale is 'yes', hide both "on loan" and "for loan"
     * - If collateral is 'yes', show "on loan" but not "for loan"
     * - If for sale is 'no' and collateral is 'no', show "for loan"
     * - If none of the above, show both "on loan" and "for loan"
     * @param filters The current filters
     * @returns An array of strings which represent the types of sort options that should be shown
     */
    enableSort(filters: any): string[] {
        const isForSale = filters.find((item: any) => item.type === 'sale');
        const isCollateral = filters.find((item: any) => item.type === "collateral");
        // If for sale is 'yes', hide both "on loan" and "for loan"
        if (isForSale?.value === 'yes') {
            return []; // Empty array means neither "on loan" nor "for loan" should not be shown
        }
        // If collateral is 'yes', show "on loan" but not "for loan"
        if (isCollateral?.value === 'yes') {
            return ['onLoan'];
        }
        // If for sale is 'no' and collateral is 'no', show "for loan"
        if (isCollateral?.value === 'no') {
            return ['forLoan'];
        }
        return ['onLoan', 'forLoan'];
    }
    /**
     * Calls the NftService's getCollections method and returns the response or an empty array on error
     * @param categories The categories to filter by
     * @param locations The locations to filter by
     * @returns The collections fetched from the BE
     */
    async getAllCollections(categories: any, locations: any) {
        return new Promise((resolve, reject) => {
            this.nftService.getCollections(categories, locations).subscribe({
                next: async (response: any) => {
                    resolve(response.data);
                },
                error: (error: any) => {
                    reject(error);
                }
            })
        })
    }

    /**
     * Updates the checked state of all collections based on the currently checked collections.
     *
     * @param checkedCollections {any[]} List of currently checked collections.
     * @param collections {any[]} List of all collections.
     * @return {void}
     */
    async updateCheckedCollections(checkedCollections: any = [], collections: any) {
        return collections.map((item: any) => {
            const checkedCollection = checkedCollections.find((collection: any) => collection.name === item.name);
            item.checked = checkedCollection ? true : false;
            return item;
        })

    }

    /**
     * Removes custom sort options from the list of filters if they are
     * present in the list of sort options.
     *
     * @param filters The list of filters
     * @param sort The list of sort options
     * @returns The filtered list of filters
     */
    removeCustomSort(filters: any[], sort: any[]) {
        return filters = filters.filter((item: any) => !sort.includes(item.name));
    }

    updateCollectionsChecked(filterList: any) {
        if (filterList.collections?.length > 0) {
            return filterList.collections.map((collection: any) => {
                collection.checked = true;
                return collection;
            });
        }
    }

    updateCategoriesChecked(filterList: any) {
        if (filterList.categories?.length > 0) {
            return filterList.categories.map((category: any) => {
                category.checked = true;
                return category;
            })
        }
    }
}