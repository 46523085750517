import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent {
  @Input() isNotificationLoader = false
  blades = new Array(12); // This will create an array with 12 elements for 12 spinner blades

}
