import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlAncherPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string, fontColor?: string): SafeHtml {
        if (fontColor) {
            // Add dynamic font color style to anchor tags
            value = value.replace(
                /<a /g,
                `<a style="color: ${fontColor};font-weight:bold;text-decoration:underline"`
            );
        }
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
