import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WebStorageService {

    constructor() { }

    /**
     * Store data in session storage
     * @param {string} key
     * @param {any} value
     */
    setItem = (key: string, value: any) => {
        sessionStorage.setItem(key, value);
    }

    /**
     * Get data from session storage
     * @param {string} key
     * @return {string} value of the key from local storage
     */
    getItem = (key: string) => {
        return sessionStorage.getItem(key);
    }

    /**
     * Remove data from session storage
     * @param {string} key
     * @return {string} value
     */
    removeItem = (key: string) => {
        return sessionStorage.removeItem(key);
    }

    /**
     * clear data from localstorage
     */
    clearItem = () => {
        let regulated = JSON.parse(this.getLocalStorage('regulated') || 'true');
        let isCustodialTypeSelected = JSON.parse(this.getItem('custodialTypeSelected') || 'false');
        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem('regulated', regulated);
        sessionStorage.setItem('custodialTypeSelected', isCustodialTypeSelected);
    }

    clearLocalStorage = () => {
        localStorage.clear();
    }

    setLocalStorage = (key: string, value: any) => {
        return localStorage.setItem(key, value);
    }

    getLocalStorage = (key: string) => {
        return localStorage.getItem(key);
    }
}
