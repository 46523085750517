import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AccountService } from 'src/app/shared/services/account.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

/**
 * Idle Service for auto logout if the time(which has been set in environment) expires
 */
export class IdleService {
    private user: { [key: string]: any } = {};



    /**
     * constructor
     */
    constructor(
        private idle: Idle,
        private webStorageService: WebStorageService,
        private accountService: AccountService,
    ) {
        this.user = this.webStorageService.getLocalStorage('user') != null ? JSON.parse(this.webStorageService.getLocalStorage('user') || 'undefined') : this.user;
        this.idle.setIdle(environment.AUTO_LOGOUT_DURATION);
        this.idle.setTimeout(environment.AUTO_LOGOUT_TIMEOUT);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleEnd.subscribe(() => this.reset());

        /** Backup to stop idle timer if still running while user is not logged in (haven't seen this be needed yet) */
        this.idle.onTimeoutWarning.subscribe(() => {
            if (!this.isLoggedIn) {
                this.idle.stop();
            }
        });

        /** called after the inactive timeout */
        this.idle.onTimeout.subscribe(() => {
            this.accountService.updateAuthentication(false);
        });
        if (this.isLoggedIn) {
            this.idle.watch();
        }
        // uncomment this after jwt expiry time is added in login response
        this.checkSessionValidity();
    }

    private get isLoggedIn(): boolean {
        this.user = this.webStorageService.getLocalStorage('user') != null ? JSON.parse(this.webStorageService.getLocalStorage('user') || 'undefined') : this.user;
        return !!this.user?.['token'];
    }

    private checkSessionValidity(): void {
        const expirationDate = this.user?.['jwt_expiring_time'];

        // Ensure the token expiration date exists
        if (expirationDate) {
            const currentTime = new Date().getTime();
            const expTime = new Date(expirationDate).getTime();
            console.log(currentTime, expTime);

            // Check if the token is expired
            if (expTime <= currentTime) {

                this.accountService.updateAuthentication(false);
            }
        }
    }


    /**
     * start idle if logged in & not running.
     */
    public reset(): void {
        if (this.isLoggedIn && !this.idle.isRunning()) {
            this.idle.watch();
        }
    }


}
