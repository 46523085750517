import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { WebStorageService } from '../../services/web-storage.service';
import { FormControl } from '@angular/forms';
import { debounceTime, Subject } from 'rxjs';
import { FilterHelper } from './filter.helper';
import { HeightService } from '../../services/height.service';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit, OnChanges {
    @ViewChild('announcementContainer', { static: false }) announcementContainer: ElementRef | undefined;
    dynamicHeight: number = 0;
    @Input() categories: any[] = [];
    @Input() locations: any[] = [];
    @Input() collections: any[] = [];
    @Input() isSeeMoreClicked = false;
    @Output() filterEvent = new EventEmitter<{ value: any, checked: boolean, action: string, type: string }>();
    @Output() loader = new EventEmitter<boolean>(false);
    @Output() showDefaultNfts = new EventEmitter<boolean>(false);
    @Input() saleOptions: any[] = [];
    @Input() collateralOptions: any[] = [];
    @Input() allCollections: any[] = [];
    @Input() isSpecificFilter = '';
    @Input() specificFilterType = '';
    @Input() collectionLoader = false;
    @Input() priceSlider = {
        floor: 0,
        ceil: 0,
        minValue: 0,
        maxValue: 0,
        loader: false
    };
    @Input() appraisalSlider = {
        floor: 0,
        ceil: 0,
        minValue: 0,
        maxValue: 0,
        loader: false
    }
    @Input() isInSale: boolean = true;

    initialCollections: any[] = [];
    initialLocations: any[] = [];
    initialCategories: any[] = [];
    options = ["yes", "no"];
    @Output() clearFilterEvent = new EventEmitter();
    @Output() loaderEvent = new EventEmitter();
    @Output() clearFilter = new EventEmitter();
    @ViewChildren('checkbox') checkboxes: any;
    @ViewChildren('selectAll') selectAll: any;

    @Output() closeFilter = new EventEmitter();
    filterList: any = {};
    filterview: boolean = false;
    maxSalePrice: boolean = false;
    maxAppraisal: boolean = false;
    appraisalMinValueError: boolean = false;
    filters: any[] = [];
    minValueDebounceTimeout: any;
    maxValueDebounceTimeout: any;
    minValueError: boolean = false;
    category_expand: boolean = true;
    location_expand: boolean = false;
    collection_expand: boolean = false;
    price_expand: boolean = false;
    appraisal_expand: boolean = false;
    sale_expand: boolean = false;
    collateral_expand: boolean = false;
    showAll = false;
    account: any = {};
    seeAllCategory: boolean = false;
    seeAllLocation: boolean = false;
    seeAllCollection: boolean = false;
    nftTraitsLoader: boolean = false;
    @Output('totalCount') totalCount = new EventEmitter();
    searchCategory: any = [];
    searchLocation: any = [];
    searchCollection: any = [];
    isSale = false;
    isWalletpage = false;
    showOnlyButtonCategory = false;
    showOnlyButtonLocation = false;
    showOnlyButtonCollection = false;
    hoveredIndex!: number;
    searchKey = new FormControl('');
    private searchDebounce = new Subject<string>();
    constructor(
        private webStorageService: WebStorageService,
        private router: Router,
        private filterHelper: FilterHelper,
        private renderer: Renderer2,
        private heightService: HeightService
    ) { }


    ngOnInit(): void {
        this.isWalletpage = this.router.url === '/my-wallet';
        this.account = this.webStorageService.getLocalStorage('account') != null ? JSON.parse(this.webStorageService.getLocalStorage('account') || '') : this.account;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.isSeeMoreClicked && !this.saleOptions[0].checked && !this.isSpecificFilter && !this.saleOptions[1].checked) {
            this.saleOptions[0].checked = true;
            this.filterEvent.emit({ value: 'yes', checked: true, action: '', type: 'sale' });
        }
        if (this.specificFilterType) {
            this.updateFilterArrays(this.specificFilterType);
        }
        if (this.searchKey.value) {
            this.searchData(this.searchKey.value);
        }
    }

    onFilter(event: any, type: string) {
        const { value, checked, action } = event.target;
        let types: any = { category: this.categories, location: this.locations, collection: this.collections, sale: this.saleOptions, collateral: this.collateralOptions };
        if (types[type]) {
            const item = types[type].find((item: any) => (item.name === value));
            if (item) {
                item.checked = checked;
            }
        }
        setTimeout(() => {
            this.loader.emit(false);
            this.filterEvent.emit({ value, checked, action, type });
        }, 1000);
    }


    selectOnly(type: any, name: string, events?: any) {
        this.updateCheckedFilterList(type, name);
        if (type === 'category') {
            this.categories.forEach(category => {
                category.checked = (category.name === name);
            });
        }
        this.loader.emit(true);
        this.closeFilter.emit(true);
        const event = { target: { value: name, action: 'only', checked: true } };
        this.onFilter(event, type);
    }

    reset(type: string) {
        if (this.searchKey.value) {
            this.searchKey.setValue('');
            this.searchData('');
        }
        this.isSeeMoreClicked = false;
        if (type === 'category') {
            this.categories.forEach(category => {
                category.checked = true;
            })
            this.updateCollectionChecked();
        }
        if (type === 'location') {
            this.locations.forEach(location => {
                location.checked = true;
            })
            this.updateCollectionChecked();
        }
        if (type === 'price') {
            this.priceSlider.minValue = 0;
            this.priceSlider.maxValue = 0;
        }
        if (type === 'appraisal') {
            this.appraisalSlider.minValue = 0;
            this.appraisalSlider.maxValue = 0;
        }
        const event = { target: { action: 'reset' } };
        this.onFilter(event, type);
    }

    setPriceFilter() {
        this.priceSlider.loader = true;
        // this.priceSlider.disabled = true;
        let { minValue, maxValue } = this.priceSlider;
        const event = { target: { value: { minValue, maxValue } } };
        this.onFilter(event, 'price');
    }

    setAppraisalFilter() {
        this.appraisalSlider.loader = true;
        let { minValue, maxValue } = this.appraisalSlider;
        const event = { target: { value: { minValue, maxValue } } };
        this.onFilter(event, 'appraisal');
    }

    onSliderMinValueChange(event: any) {

        this.minValueError = false;
        clearTimeout(this.minValueDebounceTimeout);

        // Set a new debounce timeout
        // this.minValueDebounceTimeout = setTimeout(() => {
        const newMinValue = event.target.valueAsNumber;
        if (newMinValue > this.priceSlider.maxValue) {
            // Swap values and set error flag
            this.minValueError = true;
            const temp = this.priceSlider.maxValue;
            this.priceSlider.maxValue = newMinValue;
            this.priceSlider.minValue = temp;
        } else {
            this.minValueError = false; // Clear error if corrected
            this.priceSlider.minValue = newMinValue;
        }
        this.priceSlider.minValue = event.target.value
        if (event.target.value && Number(event.target.value) < Number(this.priceSlider.maxValue)) {
            this.minValueError = false;
        } else {
            this.minValueError = true;
        }
        // }, 100);
    }

    onSliderMaxValueChange(event: any) {
        this.minValueError = false;
        clearTimeout(this.maxValueDebounceTimeout);

        // Set a new debounce timeout
        // this.maxValueDebounceTimeout = setTimeout(() => {
        const newMaxValue = event.target.valueAsNumber;
        if (newMaxValue < this.priceSlider.minValue) {
            // Swap values and set error flag
            this.minValueError = true;
            const temp = this.priceSlider.minValue;
            this.priceSlider.minValue = newMaxValue;
            this.priceSlider.maxValue = temp;
        } else {
            this.minValueError = false; // Clear error if corrected
            this.priceSlider.maxValue = newMaxValue;
        }
        this.priceSlider.maxValue = event.target.value
        if (event.target.value && Number(event.target.value) > Number(this.priceSlider.minValue)) {
            this.minValueError = false;
        } else {
            this.minValueError = true;
        }
        // }, 1000);
    }

    onSliderAppraisalMinValueChange(event: any) {
        this.appraisalMinValueError = false;
        clearTimeout(this.minValueDebounceTimeout);

        // Set a new debounce timeout
        // this.minValueDebounceTimeout = setTimeout(() => {
        const newMinValue = event.target.valueAsNumber; // Get the typed value
        if (newMinValue > this.appraisalSlider.maxValue) {
            // Swap values
            const temp = this.appraisalSlider.maxValue;
            this.appraisalSlider.maxValue = newMinValue;
            this.appraisalSlider.minValue = temp;
        } else {
            this.appraisalSlider.minValue = newMinValue;
        }
        this.appraisalSlider.minValue = event.target.value
        if (event.target.value && Number(event.target.value) < Number(this.appraisalSlider.maxValue)) {
            this.appraisalMinValueError = false;
        } else {
            this.appraisalMinValueError = true;
        }
        // }, 10);
    }

    onSliderAppraisalMaxValueChange(event: any) {
        this.appraisalMinValueError = false;
        clearTimeout(this.maxValueDebounceTimeout);

        // Set a new debounce timeout
        // this.maxValueDebounceTimeout = setTimeout(() => {
        const newMaxValue = event.target.valueAsNumber;
        if (newMaxValue < this.appraisalSlider.minValue) {
            // Swap values
            const temp = this.appraisalSlider.minValue;
            this.appraisalSlider.minValue = newMaxValue;
            this.appraisalSlider.maxValue = temp;
        } else {
            this.appraisalSlider.maxValue = newMaxValue;
        }
        this.appraisalSlider.maxValue = event.target.value
        if (event.target.value && Number(event.target.value) > Number(this.appraisalSlider.minValue)) {
            this.appraisalMinValueError = false;
        } else {
            this.appraisalMinValueError = true;
        }
        // }, 100);
    }

    /**
     * expand and collapse
     */
    categoryViewFilter() {
        this.category_expand = !this.category_expand;
    }
    locationExpand() {
        this.location_expand = !this.location_expand;
    }
    collectionAll() {
        this.collection_expand = !this.collection_expand;
    }
    priceExpand() {
        this.price_expand = !this.price_expand;
    }
    appraisalExpand() {
        this.appraisal_expand = !this.appraisal_expand;
    }
    saleExpand() {
        this.sale_expand = !this.sale_expand;
    }
    colletralExpand() {
        this.collateral_expand = !this.collateral_expand
    }
    toggleShowAll() {
        this.showAll = !this.showAll
    }

    /**
     * Changes category list
     */
    changeCategoryList() {
        this.seeAllCategory = !this.seeAllCategory;
        this.categories = JSON.parse(JSON.stringify(this.unshiftSelectedItems(this.categories)));
    }
    /**
     * Changes location list
     */
    changeLocationList() {
        this.seeAllLocation = !this.seeAllLocation;
        this.locations = this.unshiftSelectedItems(this.locations);
    }

    /**
     * Changes collection list
     */
    changeCollectionList() {
        this.seeAllCollection = !this.seeAllCollection;
        this.collections = this.unshiftSelectedItems(this.collections);
    }
    /**
     * Triggers a search with the given search key.
     *
     * @param {string} searchKey - The keyword to search for.
     * @return {void} No return value.
     */
    searchData(searchKey: string): void {
        if (!this.filterList['categories'] && !this.filterList['locations'] && !this.filterList['collections']) {
            this.generateFilterList();
        }
        this.searchDebounce.next(searchKey);
    }

    /**
     * Listens to search key changes and performs a search after a debounce time of 500ms.
     * @private
     */
    private searchDebounceSubscription = this.searchDebounce.pipe(
        debounceTime(500)
    ).subscribe((searchKey: string) => {
        // Helper function to filter collections based on checked categories and search key
        const filterCollectionsByCategory = (collections: any[], categories: any[], searchKey: string = '') => {
            // Get the names of checked categories
            const checkedCategories = categories
                .filter(category => category.checked)
                .map(category => category.name);

            return collections.filter(collection => {
                // Check if the collection's categoryFromNftsArray includes any checked categories
                const matchesCategoryFromNfts = collection.categoryFromNftsArray.some((cat: any) =>
                    checkedCategories.includes(cat)
                );

                // Check if the collection's category matches any checked categories
                const matchesCategory = checkedCategories.includes(collection.category);

                // Check if the collection matches the search key (if provided)
                const matchesSearchKey = searchKey ? collection.name.toLowerCase().includes(searchKey.toLowerCase()) : true;

                // Return true if either category check matches and the search key check passes
                return (matchesCategoryFromNfts || matchesCategory) && matchesSearchKey;
            });
        };

        // Helper function to filter categories based on search key
        const filterCategories = (categories: any[], searchKey: string = '') => {
            return categories.filter(category =>
                searchKey ? category.name.toLowerCase().includes(searchKey.toLowerCase()) : true
            );
        };

        // If search key is present
        if (searchKey) {
            // Filter collections based on the checked categories and search key
            this.collections = filterCollectionsByCategory(this.filterList.collections, this.filterList.categories, searchKey);

            // Filter categories based on the search key
            this.categories = filterCategories(this.filterList.categories, searchKey);

            // Update other filters based on search key if needed (e.g., locations)
            const filterKeys: Array<string> = ['locations'];
            filterKeys.forEach(key => {
                const foundItem = this.filterList[key].find((item: any) =>
                    item.name.toLowerCase().includes(searchKey.toLowerCase())
                );
                (this as any)[key] = foundItem ? [foundItem] : [];
            });
        } else {
            // Apply the same logic when clearing the search: filter based on checked categories
            this.collections = filterCollectionsByCategory(this.filterList.collections, this.filterList.categories);

            // Reset categories when clearing the search
            this.categories = filterCategories(this.filterList.categories);

            // Clear other filters as needed
            const filterKeys: Array<string> = ['locations'];
            filterKeys.forEach(key => {
                (this as any)[key] = [...this.filterList[key]];
            });
        }
    });

    /**
     * Unsubscribes from the search debounce subscription when the component is destroyed.
     *
     * @return {void} No return value.
     */
    ngOnDestroy() {
        this.searchDebounceSubscription.unsubscribe();
    }

    generateFilterList() {
        const newFilterList = {
            categories: [...this.categories],
            locations: [...this.locations],
            collections: [...this.allCollections]
        };
        this.filterList = newFilterList;
    }

    closeNewfilter() {
        // reset category seeAll value and move selected categories to top of the list
        this.seeAllCategory = false;
        this.categories = this.unshiftSelectedItems(this.categories);

        // reset location seeAll value and move selected locations to top of the list
        this.seeAllLocation = false;
        this.locations = this.unshiftSelectedItems(this.locations);


        // reset collection seeAll value and move selected collections to top of the list
        this.seeAllCollection = false;
        this.collections = this.unshiftSelectedItems(this.collections);

        this.closeFilter.emit(false);
    }

    // Move selected items to top of the list
    unshiftSelectedItems(items: any[] = []) {
        let selectedItems = items.filter((item) => item.checked === true)
        let unselectedItems = items.filter((item) => item.checked === false)
        return [...selectedItems, ...unselectedItems]
    }

    trackByCategory(index: number, category: any): string {
        return category?.name;
    }

    /**
     *@param{number} index
     *@param{string} type
     */
    onHoveringLabel(index: number, type: string): void {

        switch (type) {
            case 'category':
                this.showOnlyButtonCategory = true;
                this.hoveredIndex = index;
                break;
            case 'location':
                this.showOnlyButtonLocation = true;
                this.hoveredIndex = index;

                break;
            case 'collection':
                this.showOnlyButtonCollection = true;
                this.hoveredIndex = index;
                break;
            default:
                break;
        }

    }
    updateCheckedFilterList(type: string, name: string) {
        // Get the filter list based on the type and update it
        switch (type) {
            case 'category':
                if (this.filterList?.categories?.length > 0) {
                    this.filterList.categories = this.filterList.categories.map((item: any) => ({
                        ...item,
                        checked: item.name === name  // Check only the selected item
                    }));
                } else {
                    this.categories = this.categories.map((item: any) => ({
                        ...item,
                        checked: item.name === name  // Check only the selected item
                    }))
                }
                break;

            case 'location':
                if (this.filterList?.locations?.length > 0) {
                    this.filterList.locations = this.filterList.locations.map((item: any) => ({
                        ...item,
                        checked: item.name === name  // Check only the selected item
                    }));
                } else {
                    this.locations = this.locations.map((item: any) => ({
                        ...item,
                        checked: item.name === name  // Check only the selected item
                    }))
                }
                break;
            case 'collection':
                if (this.filterList?.collections?.length > 0) {
                    this.filterList.collections = this.filterList.collections.map((item: any) => ({
                        ...item,
                        checked: item.name === name // Set `checked` true for the matching name
                    }));
                } else {
                    this.allCollections = this.allCollections.map((item: any) => ({
                        ...item,
                        checked: item.name === name // Set `checked` true for the matching name
                    }));
                }
                break;
            default:
                break;
        }
    }

    updateCollectionChecked() {
        this.filterList.collections = this.filterHelper.updateCollectionsChecked(this.filterList)
    }
    updateCategoryChecked() {
        this.filterList.categories = this.filterHelper.updateCategoriesChecked(this.filterList);

    }
    updateFilterArrays(filterType: string) {
        if (filterType === 'all') {
            this.updateCategoryChecked();
            this.updateCollectionChecked();
        } else {
            this.updateCheckedFilterList('category', filterType);
        }

    }

    ngAfterViewInit() {
        this.heightService.headerHeight$.subscribe((headerHeight) => {
            const fixedOffset = window.innerWidth <= 1199 ? 65 : 73;
            const dynamicTopValue = headerHeight + fixedOffset;

            const element = document.querySelector('.filtertopheight') as HTMLElement; // Replace with your actual selector
            if (element) {
                this.renderer.setStyle(element, 'top', `${dynamicTopValue}px`);
            }
        });
    }
}