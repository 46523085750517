import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.css']
})
export class FooterNewComponent {
  year: any;
  privacyPolicyUrl: string = '';
  userAggrementUrl: string = '';

  constructor(
    public commonService: CommonService
  ) {

  }
  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.commonService.getDocument();
  }
  /**
   * scroll to top
   */
  bottomtotop() {
    window.scrollTo(0, 0);
  }
}