<footer class="base_footer">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-sm-5">
                <div class="footer-text">
                    <p>Email: <a href="mailto:info&#64;realworld.fi">info&#64;realworld.fi</a></p>
                    <ul class="social-links">
                        <li><a href="https://discord.com/invite/6xEX48jsVX" target="_blank" rel="noopener"><em
                                    class="bi bi-discord fs-6"></em></a></li>
                        <li><a href="https://twitter.com/realworld_fi" target="_blank" rel="noopener"><em
                                    class="bi bi-twitter-x fs-6"></em></a></li>
                        <li><a href="https://www.linkedin.com/company/real-world-finance/" rel="noopener"
                                target="_blank"><em class="bi bi-linkedin fs-6"></em></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-sm-7">
                <div class="d-flex align-items-center justify-content-end gap-3">
                    <a href="{{commonService?.docUrls?.privacy_policy_url}}" target="_blank" rel="noopener">Privacy
                        policy.</a>
                    <a>|</a>
                    <a href="{{commonService?.docUrls?.user_agreement_url}}" target="_blank" rel="noopener">User
                        agreement.</a>
                </div>
            </div>

        </div>
    </div>
    <div class="backtotop" (click)="bottomtotop()">
        <em class="bi bi-arrow-up"></em>
    </div>
</footer>