import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DebounceClickDirective } from './../shared/directives/debounce-click.directive';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ContractTransactionsComponent } from './components/contract-transactions/contract-transactions.component';
import { FiltersComponent } from './components/filters/filters.component';
import { FooterNewComponent } from './components/footer-new/footer-new.component';
import { HeaderNewComponent } from './components/header-new/header-new.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ProgressModalComponent } from './components/progress-modal/progress-modal.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TransactionDetailsModalComponent } from './components/transaction-details-modal/transaction-details-modal.component';
import { TransactionLogsComponent } from './components/transaction-logs/transaction-logs.component';
import { UserAuthenticationCardComponent } from './components/user-authentication-card/user-authentication-card.component';
import { NoSpecialCharactersDirective } from './directives/app-no-special-chars.directive';
import { CopyDirective } from './directives/copy.directive';
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';
import { NoLeadingSpaceDirective } from './directives/no-leading-space.directive';
import { PreventNegativeDirective } from './directives/prevent-negative.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SafeHtmlAncherPipe } from './pipes/safeHtmlAncher.pipe';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { UtcConverterPipe } from './pipes/utc-convwrter.pipe';
import { NumericOnlyDirective } from './directives/app-number-input.directive';
import { MailverficationComponent } from './components/mailverfication/mailverfication.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
@NgModule({
    declarations: [
        HeaderNewComponent,
        FooterNewComponent,
        SidebarComponent,
        FiltersComponent,
        CustomTooltipDirective,
        DebounceClickDirective,
        UtcConverterPipe,
        CopyDirective,
        ThousandSeparatorPipe,
        ContractTransactionsComponent,
        TransactionLogsComponent,
        PaginationComponent,
        TransactionDetailsModalComponent,
        PreventNegativeDirective,
        SafePipe,
        SafeHtmlPipe,
        SafeHtmlAncherPipe,
        UserAuthenticationCardComponent,
        NoLeadingSpaceDirective,
        NoSpecialCharactersDirective,
        SpinnerComponent,
        ConfirmationModalComponent,
        ProgressModalComponent,
        TruncatePipe,
        NumericOnlyDirective,
        MailverficationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ModalModule,
        CarouselModule,
        NgxSliderModule,
        InfiniteScrollModule
    ],
    exports: [
        HeaderNewComponent,
        FooterNewComponent,
        SidebarComponent,
        FiltersComponent,
        CustomTooltipDirective,
        DebounceClickDirective,
        UtcConverterPipe,
        NgxSliderModule,
        ThousandSeparatorPipe,
        ContractTransactionsComponent,
        TransactionLogsComponent,
        PaginationComponent,
        PreventNegativeDirective,
        SafePipe, UserAuthenticationCardComponent,
        NoLeadingSpaceDirective,
        NoSpecialCharactersDirective,
        SpinnerComponent,
        ConfirmationModalComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule,
        RouterModule,
        ProgressModalComponent,
        SafeHtmlAncherPipe,
        TruncatePipe,
        NumericOnlyDirective,
        MailverficationComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [CopyDirective]
})
export class SharedModule { }
